import { parseCookies } from 'nookies';

export const authHeader = (token) => {
  let authToken = token;

  if (!token) {
    const cookies = parseCookies();
    authToken = cookies.authToken;
  }
  if (authToken) {
    return { Authorization: authToken };
  }
  return {};
};
