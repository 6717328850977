import { format, utcToZonedTime } from 'date-fns-tz';

import { endOfWeek, add, isThisWeek, isBefore, parseISO, getDay, isMonday } from 'date-fns';

// eslint-disable-next-line no-use-before-define
const UTCTime = formatTimezone(new Date());

const end = endOfWeek(UTCTime, {
  weekStartsOn: 1,
});

const nextWednesday = add(end, {
  days: 3,
});

const nextFriday = add(end, {
  days: 5,
});

const wednesdayAfterWeek = add(end, {
  days: 10,
});

const fridayAfterWeek = add(end, {
  days: 12,
});

// Monday 8:00 AM
// const mondayPaymentTime = add(end, {
//   days: -6,
//   hours: -16,
// });

const mondayPaymentTime = add(end, {
  days: 1,
  hours: -16,
});

// Tuesday 8:00 AM
const tuesdayPaymentTime = add(end, {
  days: -5,
  hours: -16,
});

// Friday 8:00 AM
const fridayPaymentTime = add(end, {
  days: -2,
  hours: -8,
});

// Saturday 8:00 AM
const saturdayPaymentTime = add(end, {
  days: -1,
  hours: -16,
});

// Sunday 8:00 AM
const sundayPaymentTimeMorning = add(end, {
  // hours: -16,
});

// Sunday 11:59 PM
const sundayPaymentTime = end;

const monday = add(end, {
  days: -6,
});

const tuesday = add(end, {
  days: -5,
});

const wednesday = add(end, {
  days: -4,
});

const thursday = add(end, {
  days: -3,
});

const friday = add(end, {
  days: -2,
});

// Friday
const paymentDate = add(end, {
  days: -2,
});
// Sunday
const paymentDateFridayDelivery = add(end, {
  days: 0,
});
// Next Friday
const paymentDateAfterWeek = add(end, {
  days: 5,
});
// Next Sunday
const paymentDateAfterWeekFridayDelivery = add(end, {
  days: 7,
});

const changeMealsDateFriday = add(end, {
  days: -2,
  hours: -8,
});

const changeMealsDateAfterWeekFriday = add(end, {
  days: 5,
  hours: -8,
});

const changeMealsDateSunday = add(end, {
  days: 0,
});

const changeMealsDateAfterWeekSunday = add(end, {
  days: 7,
});

const changeMealsDateMonday = add(end, {
  days: 1,
});

const changeMealsDateTuesday = add(end, {
  days: 2,
});

const changeMealsDateAfterWeekMonday = add(end, {
  days: 8,
});

const changeMealsDateAfterWeekTuesday = add(end, {
  days: 9,
});

const fridayFourPM = add(end, {
  days: -2,
  hours: -8,
});

const deliveryDay = format(nextWednesday, 'EEE do MMM');

const calculateDeliveryDate = (lastOrder = {}) => {
  if (!Object.keys(lastOrder).length) {
    return nextWednesday;
  }

  if (isThisWeek(UTCTime) && isBefore(UTCTime, parseISO(lastOrder.deliveryDate))) {
    return parseISO(lastOrder.deliveryDate);
    // return wednesday;
  }

  return nextWednesday;
};

function formatTimezone(date) {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
}

function formatDateToUTC(date, dateFormat = 'dd LLL yyyy hh:mm:ss') {
  if (!date) {
    return '';
  }

  const dateObj = date instanceof Date ? date : new Date(date);
  const timeZone = 'GMT';
  const zonedDate = utcToZonedTime(dateObj, timeZone);

  return format(zonedDate, dateFormat);
}

function getWeekStartOn(packageScheduleId) {
  if (packageScheduleId === 1) return 1;
  if (packageScheduleId === 4) return 2;
}

function checkIsLastPaymentDateOnThisWeek(lastPayment, packageScheduleId) {
  // If last payment was taken on this week -> add 7 days to pause duration

  const isTodayMonday = isMonday(UTCTime);
  const isPaymentOnTheCurrentWeek =
    lastPayment &&
    isThisWeek(formatTimezone(parseISO(lastPayment)), {
      weekStartsOn: getWeekStartOn(packageScheduleId),
    });

  // if today Monday and next delivery will be this Friday -> set up 0 to prevent long stop time range
  if (isTodayMonday && packageScheduleId === 4 && isPaymentOnTheCurrentWeek) {
    return 0;
  }

  if (isPaymentOnTheCurrentWeek) {
    return 7;
  }

  return 0;

  // return lastPayment &&
  //   isThisWeek(formatTimezone(parseISO(lastPayment)), { weekStartsOn: getWeekStartOn(packageScheduleId) })
  //   ? 7
  //   : 0;
}

function checkIsBeforeFirstPaymentDay(packageScheduleId) {
  if (packageScheduleId === 1) return isBefore(UTCTime, fridayPaymentTime);
  if (packageScheduleId === 4) return isBefore(UTCTime, saturdayPaymentTime);
}

function calculateOffsetDays() {
  const day = getDay(UTCTime); // 0 | 1 | 2 | 3 | 4 | 5 | 6. Where Sunday = 0, Monday = 1 ...
  const wednesdayDay = 3;

  if (day === 0) return wednesdayDay - 7;

  return wednesdayDay - day;
}

function calculatePauseEndDate(daysToAdd) {
  const pauseEndDate = add(UTCTime, {
    days: daysToAdd,
  });

  return pauseEndDate;
}

function calculateNextDeliveryDate(daysToAdd, packageScheduleId) {
  const daysToAddToNextDelivery = packageScheduleId === 1 ? 7 : 9;
  const nextDeliveryDate = add(UTCTime, {
    days: daysToAdd + daysToAddToNextDelivery,
  });

  return nextDeliveryDate;
}

export default {
  UTCTime,
  nextWednesday,
  nextFriday,

  wednesdayAfterWeek,
  fridayAfterWeek,

  paymentDate,
  paymentDateAfterWeek,
  paymentDateFridayDelivery,
  paymentDateAfterWeekFridayDelivery,

  changeMealsDateFriday,
  changeMealsDateAfterWeekFriday,
  changeMealsDateSunday,
  changeMealsDateAfterWeekSunday,
  changeMealsDateMonday,
  changeMealsDateTuesday,
  changeMealsDateAfterWeekMonday,
  changeMealsDateAfterWeekTuesday,

  deliveryDay,

  monday,
  tuesday,
  wednesday,
  thursday,
  friday,

  end,

  mondayPaymentTime,
  tuesdayPaymentTime,
  fridayPaymentTime,
  saturdayPaymentTime,
  sundayPaymentTimeMorning,
  sundayPaymentTime,

  fridayFourPM,

  calculateDeliveryDate,
  formatTimezone,
  formatDateToUTC,
  getWeekStartOn,
  checkIsLastPaymentDateOnThisWeek,
  checkIsBeforeFirstPaymentDay,
  calculateOffsetDays,
  calculatePauseEndDate,
  calculateNextDeliveryDate,
};
