import API from 'utils/API';
import { authHeader } from '../utils/authHeader';

const setupCardIntent = async (setupIntentData) => {
  const body = {
    ...setupIntentData,
  };

  const data = await API.post(`/subscriptions/setup-card-intent`, body, {
    headers: {
      ...authHeader(),
    },
  });
  return data;
};

const setupIntent = async (setupIntentData) => {
  const body = {
    ...setupIntentData,
  };

  const data = await API.post(`/subscriptions/setup-intent`, body, {
    headers: {
      ...authHeader(),
    },
  });
  return data;
};

const createSubscription = async (subscriptionData, token) => {
  const body = {
    ...subscriptionData,
  };

  const data = await API.post(`/subscriptions`, body, {
    headers: {
      ...authHeader(token),
    },
  });
  return data;
};

const changeSubscriptionStatus = async (subscriptionData) => {
  const body = {
    ...subscriptionData,
  };

  const data = await API.patch(`/subscriptions/status`, body);
  return data;
};

const changeSubscriptionDeliveryDay = async (subscriptionData) => {
  const body = {
    ...subscriptionData,
  };

  const data = await API.patch(`/subscriptions/delivery-day`, body);
  return data;
};

const changeSubscriptionPlan = async (planData) => {
  const body = {
    ...planData,
  };

  const { data } = await API.patch(`/subscriptions/plan`, body);
  return data;
};

const checkCoupon = async (coupon = '') => {
  const data = await API.get(`/subscriptions/coupon?coupon=${coupon.toUpperCase()}`);

  return data;
};

export default {
  setupCardIntent,
  setupIntent,
  createSubscription,
  changeSubscriptionStatus,
  changeSubscriptionPlan,
  changeSubscriptionDeliveryDay,
  checkCoupon,
};
